import { Checkbox, CircularProgress, Link } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { parseURITemplate } from '@superdispatch/uri';
import {
  useOrderInspectionPhotoIssues,
  useRelatedLegsMap,
} from 'orders/data/OrderAPI';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import { useProductTiers } from 'shared/data/TiersUtils';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import Order from 'shared/types/order';
import { DeliveryETA } from 'shared/ui/DeliveryETA';
import { DetailedFormattedDate } from 'shared/ui/DetailedFormattedDate';
import { PickupETA } from 'shared/ui/PickupETA';
import { Table, TableConfig } from 'shared/ui/Table';
import { TextOverflow } from 'shared/ui/TextOverflow';
import { makeCityStateZip } from 'shared/utils/AddressUtils';
import { getOnlineBOLUrl } from '../actions/OrderActionUtils';
import { OrderStatus } from '../index';
import { OrderInspectionPhotoIssues } from '../OrderInspectionPhotoIssues';
import { GrossProfit } from './GrossProfit';
import {
  Column,
  ManageColumns,
  OrderListItemAge,
  OrderListPayment,
  OrderListPrice,
  OrderListVehicles,
  OrderListVehiclesLotNumbers,
  OrderListVehiclesVins,
  OrderTableNumber,
} from './index';
import { OrderListCarrierDate } from './OrderListCarrierDate';
import { OrderListCustomerDate } from './OrderListCustomerDate';
import { WhoAmI } from 'shared/types/user';

export type SortKey = string;
export type SortOrder = string;

const statusColumn = {
  key: 'status',
  content: 'Status',
  isSortable: true,
  sortKey: 'status',
};
const purchaseOrderNumberColumn = {
  key: 'purchaseOrderNumber',
  content: 'PO Number',
  isSortable: true,
  sortKey: 'purchaseOrderNumber',
};
const createdAtColumn = {
  key: 'createdAt',
  content: 'Created/Age',
  isSortable: true,
  sortKey: 'createdAt',
};
const customerColumn = {
  key: 'customer.name',
  content: 'Customer',
  isSortable: true,
  sortKey: 'customer',
};
const tariffColumn = {
  key: 'customerPayment.tariff',
  content: 'Tariff',
  isSortable: true,
};
const vehiclesColumn = {
  key: 'vehicles',
  content: 'Vehicles',
};
const vinColumn = {
  key: 'vin',
  content: 'VIN',
};
const lotNumberColumn = {
  key: 'lotNumber',
  content: 'Lot Number',
};
const pickupColumn = {
  key: 'pickup.venue.city',
  content: 'Pickup',
  isSortable: true,
};
const pickupBusinessNameColumn = {
  key: 'pickup.venue.name',
  content: 'Pickup Business Name',
  isSortable: true,
};
const deliveryColumn = {
  key: 'delivery.venue.city',
  content: 'Delivery',
  isSortable: true,
};
const deliveryBusinessNameColumn = {
  key: 'delivery.venue.name',
  content: 'Deliv. Business Name',
  isSortable: true,
};
const firstAvailablePickupDateColumn = {
  key: 'pickup.firstAvailablePickupDate',
  content: '1st Avail. Pickup Date',
  isSortable: true,
};
const customerPickupDateColumn = {
  key: 'pickup.scheduledAtByCustomer',
  content: 'Customer Pickup Date',
  isSortable: true,
};
const carrierPickupDateColumn = {
  key: 'pickup.scheduledAt',
  content: 'Carrier Pickup Date',
  isSortable: true,
};
const pickupDateColumn = {
  key: 'pickup.scheduledAt',
  content: 'Pickup Date',
  isSortable: true,
};
const pickupCompletedDateColumn = {
  key: 'pickup.completedAt',
  content: 'Pickup Completed Date',
  isSortable: true,
};
const pickupAdjustedDateColumn = {
  key: 'pickup.adjustedDate',
  content: 'Pickup Adjusted Date',
  isSortable: true,
};
const customerDeliveryDateColumn = {
  key: 'delivery.scheduledAtByCustomer',
  content: 'Customer Deliv. Date',
  isSortable: true,
};
const carrierDeliveryDateColumn = {
  key: 'delivery.scheduledAt',
  content: 'Carrier Deliv. Date',
  isSortable: true,
};
const deliveryDateColumn = {
  key: 'delivery.scheduledAt',
  content: 'Deliv. Date',
  isSortable: true,
};
const deliveryCompletedDateColumn = {
  key: 'delivery.completedAt',
  content: 'Deliv. Completed Date',
  isSortable: true,
};
const deliveryAdjustedDateColumn = {
  key: 'delivery.adjustedDate',
  content: 'Deliv. Adjusted Date',
  isSortable: true,
};
const contactColumn = {
  key: 'customer.contactName',
  content: 'Contact',
  isSortable: true,
};
const carrierColumn = {
  key: 'activeOffer.carrierName',
  content: 'Carrier',
  isSortable: true,
};
const priceColumn = {
  key: 'price',
  content: 'Carrier Price',
  isSortable: true,
};
const grossProfiColumn = {
  key: 'profit',
  content: 'Gross Profit',
};
const salesPersonColumn = {
  key: 'salesRepresentative',
  content: 'Salesperson',
};
const dispatcherColumn = {
  key: 'dispatcherName',
  content: 'Dispatcher',
  isSortable: true,
};
const changedAtColumn = {
  key: 'changedAt',
  content: 'Updated at',
  isSortable: true,
};

const getBasicTierBrokersColumns = (user: WhoAmI | undefined): Column[] => {
  return [
    statusColumn,
    createdAtColumn,
    purchaseOrderNumberColumn,
    vehiclesColumn,
    vinColumn,
    lotNumberColumn,
    pickupColumn,
    pickupBusinessNameColumn,
    deliveryColumn,
    deliveryBusinessNameColumn,
    !!user?.shipper.is_cd_integration_enabled && firstAvailablePickupDateColumn,
    carrierPickupDateColumn,
    pickupAdjustedDateColumn,
    pickupCompletedDateColumn,
    carrierDeliveryDateColumn,
    deliveryAdjustedDateColumn,
    deliveryCompletedDateColumn,
    carrierColumn,
    priceColumn,
    grossProfiColumn,
    salesPersonColumn,
    dispatcherColumn,
    changedAtColumn,
  ].filter((value) => !!value) as Column[];
};

const getAdvancedTierBrokersColumns = (user: WhoAmI | undefined): Column[] => {
  return [
    statusColumn,
    createdAtColumn,
    purchaseOrderNumberColumn,
    customerColumn,
    tariffColumn,
    vehiclesColumn,
    vinColumn,
    lotNumberColumn,
    pickupColumn,
    pickupBusinessNameColumn,
    deliveryColumn,
    deliveryBusinessNameColumn,
    !!user?.shipper.is_cd_integration_enabled && firstAvailablePickupDateColumn,
    customerPickupDateColumn,
    carrierPickupDateColumn,
    pickupAdjustedDateColumn,
    pickupCompletedDateColumn,
    customerDeliveryDateColumn,
    carrierDeliveryDateColumn,
    deliveryAdjustedDateColumn,
    deliveryCompletedDateColumn,
    carrierColumn,
    priceColumn,
    grossProfiColumn,
    salesPersonColumn,
    dispatcherColumn,
    changedAtColumn,
  ].filter((value) => !!value) as Column[];
};

const customersColumns: Column[] = [
  statusColumn,
  createdAtColumn,
  purchaseOrderNumberColumn,
  tariffColumn,
  vehiclesColumn,
  vinColumn,
  lotNumberColumn,
  pickupColumn,
  pickupBusinessNameColumn,
  pickupCompletedDateColumn,
  deliveryColumn,
  deliveryBusinessNameColumn,
  deliveryCompletedDateColumn,
  pickupDateColumn,
  deliveryDateColumn,
  contactColumn,
];

const localStorageItem = 'ordersTableActiveColumns';

const hideDates = (isCustomer: boolean, status: string) => {
  return isCustomer && ['new', 'submitted'].includes(status);
};

export interface OrderTableProps {
  searchQuery?: string;
  orders: Order[];
  selectedOrders: Order[];
  isLoading: boolean;
  renderActions?: (order: Order) => React.ReactNode;
  renderNumber?: (order: Order) => React.ReactNode;
  sortKey?: SortKey;
  sortOrder?: SortOrder;
  onSort?: (sortKey: SortKey, sortOrder: SortOrder) => void;
  toggleSelectOrder: (id: number) => void;
  onSelectAllOrders: () => void;
  onUnselectAllOrders: () => void;
  showRequestsCheckbox?: boolean;
}

export function OrderTable({
  orders,
  isLoading,
  renderActions,
  renderNumber,
  sortKey,
  sortOrder,
  onSort,
  searchQuery,
  toggleSelectOrder,
  onUnselectAllOrders,
  onSelectAllOrders,
  selectedOrders,
  showRequestsCheckbox = true,
}: OrderTableProps) {
  const { user } = useUserState();
  const { isAdvancedTier } = useProductTiers();
  const orderIds = useMemo(() => orders.map((o) => o.id), [orders]);
  const orderInspectionPhotoIssues = useOrderInspectionPhotoIssues(orderIds);

  const brokersColumns = isAdvancedTier
    ? getAdvancedTierBrokersColumns(user)
    : getBasicTierBrokersColumns(user);

  const userColumnsMap = {
    BROKER: brokersColumns,
    CUSTOMER: customersColumns,
  };

  const [visibleColumnKeys, setVisibleColumnKeys] = useState<string[]>([]);
  const shipperType = user?.shipper.shipper_type;
  const isCustomer = shipperType === 'CUSTOMER';
  const userColumns = useMemo(
    () => (shipperType ? userColumnsMap[shipperType] : []),
    [shipperType],
  );

  const columns = useMemo(
    () =>
      userColumns.map((column) => ({
        ...column,
        isVisible: visibleColumnKeys.includes(column.key),
      })),
    [userColumns, visibleColumnKeys],
  );

  const orderIDsWithLegs = useMemo(
    () => orders.filter(({ has_loads }) => has_loads).map((x) => x.id),
    [orders],
  );

  const relatedLegsMap = useRelatedLegsMap(orderIDsWithLegs);

  const setActiveKeys = (selectedColumnKeys: string[]) => {
    localStorage.setItem(localStorageItem, JSON.stringify(selectedColumnKeys));
    setVisibleColumnKeys(selectedColumnKeys);
  };
  const toggleColumn = (key: Column['key'], isVisible: Column['isVisible']) => {
    const columnKeyIndex = visibleColumnKeys.findIndex(
      (activeColumnKey) => activeColumnKey === key,
    );
    if (isVisible && columnKeyIndex === -1) {
      setActiveKeys([...visibleColumnKeys, key]);
    }
    if (!isVisible && columnKeyIndex > -1) {
      const newActiveColumnKeys = Array.from(visibleColumnKeys);
      newActiveColumnKeys.splice(columnKeyIndex, 1);
      setActiveKeys(newActiveColumnKeys);
    }
  };

  const config: TableConfig = {
    columns: [
      {
        key: 'number',
        content: 'ID',
        label: showRequestsCheckbox ? (
          <Checkbox
            checked={selectedOrders.length === orders.length}
            onClick={(event) => event.stopPropagation()}
            onChange={() =>
              selectedOrders.length === orders.length
                ? onUnselectAllOrders()
                : onSelectAllOrders()
            }
          />
        ) : null,
        isSortable: true,
        sortKey: 'number',
        isVisible: true,
      },
      {
        key: 'actions',
        content: (
          <ManageColumns columns={columns} onToggleColumn={toggleColumn} />
        ),
        alignment: 'left',
        isVisible: true,
      },
      ...columns,
    ],
    rows: orders.map((order) => {
      return {
        key: order.id,
        cells: [
          {
            key: 'number',
            verticalAligment: 'bottom',
            content: renderNumber ? (
              renderNumber(order)
            ) : (
              <OrderTableNumber
                id={order.id}
                selectable={true}
                selected={selectedOrders.some((x) => x.id === order.id)}
                number={order.number}
                toggleSelectOrder={toggleSelectOrder}
                link={
                  order.is_active
                    ? `/orders/view/${order.guid}`
                    : `/orders/inactive/view/${order.guid}`
                }
              />
            ),
          },
          {
            key: 'actions',
            verticalAligment: 'bottom',
            content: renderActions?.(order),
          },
          ...columns.map(({ key }) => {
            if (key === 'status') {
              return {
                key,
                content: (
                  <OrderStatus
                    order={order}
                    showDelays={true}
                    hasUnassignedVehicles={
                      relatedLegsMap[order.id]?.has_unassigned_vehicles
                    }
                  />
                ),
              };
            }

            if (key === 'purchaseOrderNumber') {
              return {
                key,
                content: order.purchase_order_number,
              };
            }

            if (key === 'createdAt') {
              return {
                key,
                content: (
                  <span>
                    <OrderListItemAge order={order} />
                  </span>
                ),
              };
            }

            if (key === 'customer.name') {
              return {
                key,
                content: order.customer && (
                  <TextOverflow size={150}>{order.customer.name}</TextOverflow>
                ),
              };
            }

            if (key === 'customerPayment.tariff') {
              return {
                key,
                content: (
                  <OrderListPayment
                    amount={order.customer_payment?.tariff}
                    terms={order.customer_payment?.terms}
                  />
                ),
              };
            }

            if (key === 'vehicles') {
              return {
                key,
                content: (
                  <Stack space="xxsmall">
                    <OrderInspectionPhotoIssues
                      onClick={() => {
                        trackEvent('Shipper Clicked Non-vehicle Photo Issues', {
                          utm_medium: 'order_list_table_view',
                        });
                      }}
                      online_bol_url={getOnlineBOLUrl(
                        order,
                        'Inspection Photo Issues Link',
                      )}
                      inspection_photo_issues_count={
                        orderInspectionPhotoIssues.data?.[order.id]
                      }
                    />
                    <OrderListVehicles
                      order={order}
                      shipperType={shipperType}
                      vinSearchText={searchQuery}
                    />
                  </Stack>
                ),
              };
            }

            if (key === 'vin') {
              return {
                key,
                content: (
                  <OrderListVehiclesVins
                    order={order}
                    vinSearchText={searchQuery}
                  />
                ),
              };
            }

            if (key === 'lotNumber') {
              return {
                key,
                content: (
                  <OrderListVehiclesLotNumbers
                    vehicles={order.vehicles}
                    vinSearchText={searchQuery}
                  />
                ),
              };
            }

            if (key === 'pickup.venue.city') {
              return {
                key,
                content: makeCityStateZip(order.pickup?.venue),
              };
            }
            if (key === 'pickup.venue.name') {
              return {
                key,
                content: order.pickup?.venue?.name,
              };
            }
            if (key === 'delivery.venue.name') {
              return {
                key,
                content: order.delivery?.venue?.name,
              };
            }

            if (key === 'delivery.venue.city') {
              return {
                key,
                content: makeCityStateZip(order.delivery?.venue),
              };
            }

            if (key === 'pickup.firstAvailablePickupDate') {
              return {
                key,
                content: order.pickup?.first_available_pickup_date && (
                  <DetailedFormattedDate
                    date={order.pickup.first_available_pickup_date}
                  />
                ),
              };
            }
            if (key === 'pickup.scheduledAt') {
              return {
                key,
                content: !hideDates(isCustomer, order.status) &&
                  order.pickup?.scheduled_at && (
                    <OrderListCarrierDate type="pickup" order={order} />
                  ),
              };
            }

            if (key === 'pickup.scheduledAtByCustomer') {
              return {
                key,
                content: !hideDates(isCustomer, order.status) &&
                  order.pickup?.scheduled_at_by_customer && (
                    <OrderListCustomerDate type="pickup" order={order} />
                  ),
              };
            }

            if (key === 'pickup.completedAt') {
              return {
                key,
                content: order.pickup?.completed_at ? (
                  <DetailedFormattedDate date={order.pickup.completed_at} />
                ) : (
                  order.eta_information?.pickup_eta?.date_until && (
                    <PickupETA pickupETA={order.eta_information.pickup_eta} />
                  )
                ),
              };
            }

            if (key === 'pickup.adjustedDate') {
              return {
                key,
                content: order.pickup?.adjusted_date && (
                  <DetailedFormattedDate date={order.pickup.adjusted_date} />
                ),
              };
            }

            if (key === 'delivery.scheduledAt') {
              return {
                key,
                content: !hideDates(isCustomer, order.status) &&
                  order.delivery?.scheduled_at && (
                    <OrderListCarrierDate type="delivery" order={order} />
                  ),
              };
            }

            if (key === 'delivery.scheduledAtByCustomer') {
              return {
                key,
                content: !hideDates(isCustomer, order.status) &&
                  order.delivery?.scheduled_at_by_customer && (
                    <OrderListCustomerDate type="delivery" order={order} />
                  ),
              };
            }

            if (key === 'delivery.completedAt') {
              const deliveryETA = order.eta_information?.delivery_eta;

              return {
                key,
                content: order.delivery?.completed_at ? (
                  <DetailedFormattedDate date={order.delivery.completed_at} />
                ) : (
                  deliveryETA && (
                    <DeliveryETA
                      delivery={order.delivery}
                      deliveryETA={deliveryETA}
                    />
                  )
                ),
              };
            }

            if (key === 'delivery.adjustedDate') {
              return {
                key,
                content: order.delivery?.adjusted_date && (
                  <DetailedFormattedDate date={order.delivery.adjusted_date} />
                ),
              };
            }

            if (key === 'customer.contactName') {
              return {
                key,
                content: order.customer?.contact_name,
              };
            }

            if (key === 'activeOffer.carrierName') {
              return {
                key,
                content: order.active_offer && (
                  <TextOverflow size={150}>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={parseURITemplate(
                        `/manage-carriers/${order.active_offer.carrier_guid}{?utm_medium,utm_content}`,
                        {
                          utm_medium: 'Orders List',
                          utm_content: `${order.status}_order_status`,
                        },
                      )}
                      component={RouterLink}
                    >
                      {order.active_offer.carrier_name}
                    </Link>
                  </TextOverflow>
                ),
              };
            }

            if (key === 'price') {
              return {
                key,
                content: <OrderListPrice order={order} />,
              };
            }
            if (key === 'profit') {
              return {
                key,
                content: order.customer_payment?.tariff != null &&
                  order.price != null && (
                    <GrossProfit
                      tariff={order.customer_payment.tariff}
                      price={order.price}
                    />
                  ),
              };
            }

            if (key === 'salesRepresentative') {
              return {
                key,
                content: order.sales_representative,
              };
            }

            if (key === 'dispatcherName') {
              return {
                key,
                content: order.dispatcher_name,
              };
            }

            if (key === 'changedAt') {
              return {
                key,
                content: <DetailedFormattedDate date={order.changed_at} />,
              };
            }

            return { key: 'error' };
          }),
        ],
      };
    }),
  };

  useEffect(() => {
    const stringifiedActiveColumnKeys = localStorage.getItem(localStorageItem);

    if (stringifiedActiveColumnKeys) {
      const storedVisibleColumnKeys = JSON.parse(stringifiedActiveColumnKeys);
      setVisibleColumnKeys(storedVisibleColumnKeys);
    } else {
      setVisibleColumnKeys(userColumns.map((x) => x.key));
    }
  }, [setVisibleColumnKeys, userColumns]);

  return isLoading ? (
    <div style={{ textAlign: 'center', margin: '2rem' }}>
      <CircularProgress variant="indeterminate" size={40} />
    </div>
  ) : (
    <Table
      config={config}
      sortKey={sortKey}
      sortOrder={sortOrder}
      onSort={onSort}
    />
  );
}
