import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { APIPageResponse, useAPI } from 'shared/api/API';
import { APIMutationOptions, useAPIMutation } from 'shared/api/APIMutation';
import { useAPIPageQuery } from 'shared/api/APIPageQuery';
import { Order } from 'shared/types/order';
import { debounceBatchCalls } from 'shared/utils/FunctionUtils';
import { useProductTiers } from '../../shared/data/TiersUtils';
import {
  isPriceNegotiationAvailable,
  PriceNegotiationDTO,
  PriceNegotiationFormDTO,
  priceNegotiationSchema,
} from './dto/PriceNegotiationDTO';
import { useUserState } from 'shared/data/AppUserState';

const batchPriceNegotiationCalls = debounceBatchCalls();

export function usePriceNegotiationList(orderGuid: string | null) {
  const { requestPage } = useAPI();
  return useAPIPageQuery<PriceNegotiationDTO>(
    ['orders', 'price-negotiation', { orderGuid }],
    () => {
      if (!orderGuid) {
        throw Error('Missing order guid');
      }
      return batchPriceNegotiationCalls([orderGuid], (param) =>
        requestPage(
          '/internal/orders/price_negotiations{?orderGuids}',
          (data) => priceNegotiationSchema.cast(data),
          { orderGuids: param },
        ),
      );
    },
    { enabled: !!orderGuid, staleTime: 10 * 1000 },
  );
}

function usePriceNegotiationCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    function setPriceNegotiation(priceNegotiation: PriceNegotiationDTO) {
      // prevent form reinitialization
      requestAnimationFrame(() => {
        queryClient.setQueryData(
          [
            'orders',
            'price-negotiation',
            { orderGuid: priceNegotiation.order_guid },
          ],
          { objects: [priceNegotiation] },
        );
      });
    }

    function updatePriceNegotiation(
      guid: string,
      fn: (prev: PriceNegotiationDTO) => PriceNegotiationDTO,
    ) {
      const cache = queryClient.getQueriesData<
        APIPageResponse<PriceNegotiationDTO>['data'] | undefined
      >(['orders', 'price-negotiation']);
      const prevPriceNegotiation = cache
        .flatMap(([_key, data]) => data)
        .flatMap((item) => item?.objects)
        .find((item) => item?.guid === guid);

      if (prevPriceNegotiation) {
        // prevent form reinitialization
        requestAnimationFrame(() => {
          queryClient.setQueryData<
            APIPageResponse<PriceNegotiationDTO>['data'] | undefined
          >(
            [
              'orders',
              'price-negotiation',
              { orderGuid: prevPriceNegotiation.order_guid },
            ],
            (prev) =>
              !prev
                ? undefined
                : {
                    ...prev,
                    objects: [fn(prevPriceNegotiation)],
                  },
          );
        });
      }
    }

    return { setPriceNegotiation, updatePriceNegotiation };
  }, [queryClient]);
}

export function usePriceNegotiationMap(orderGuid: string | null) {
  const { data, ...meta } = usePriceNegotiationList(orderGuid);
  const map = useMemo(() => {
    const priceMap = new Map<string, PriceNegotiationDTO>();

    for (const item of data?.objects || []) {
      priceMap.set(item.order_guid, item);
    }

    return priceMap;
  }, [data?.objects]);

  return { map, data, ...meta };
}

export function usePriceNegotiationByGuid(orderGuid: string | null) {
  const { map, data, ...rest } = usePriceNegotiationMap(orderGuid);
  return {
    ...rest,
    data: orderGuid == null ? undefined : map.get(orderGuid),
  };
}

export function usePriceNegotiation(order: Order) {
  const { isAdvancedTier } = useProductTiers();
  const { user } = useUserState();
  const enabled =
    isPriceNegotiationAvailable(order) &&
    isAdvancedTier &&
    user?.shipper.shipper_type === 'BROKER';

  return usePriceNegotiationByGuid(enabled ? order.guid : null);
}

export function usePriceNegotiationAPI() {
  const cache = usePriceNegotiationCache();
  const { requestResource, request } = useAPI();
  return useMemo(
    () => ({
      createPriceNegotiation: (payload: PriceNegotiationFormDTO) =>
        requestResource(
          'POST /internal/orders/price_negotiations',
          (data) => priceNegotiationSchema.cast(data),
          { json: payload },
        ).then((response) => {
          cache.setPriceNegotiation(response);
          return response;
        }),
      updatePriceNegotiation: (
        guid: string,
        payload: PriceNegotiationFormDTO,
      ) =>
        requestResource(
          'PUT /internal/orders/price_negotiations/{guid}',
          (data) => priceNegotiationSchema.cast(data),
          { json: payload, guid },
        ).then((response) => {
          cache.setPriceNegotiation(response);
          return response;
        }),
      enablePriceNegotiation: (guid: string) =>
        request('PUT /internal/orders/price_negotiations/{guid}/enable', {
          guid,
        }).then((response) => {
          cache.updatePriceNegotiation(guid, (prev) => ({
            ...prev,
            active: true,
          }));
          return response;
        }),
      disablePriceNegotiation: (guid: string) =>
        request('PUT /internal/orders/price_negotiations/{guid}/disable', {
          guid,
        }).then((response) => {
          cache.updatePriceNegotiation(guid, (prev) => ({
            ...prev,
            active: false,
          }));
          return response;
        }),
    }),
    [cache, request, requestResource],
  );
}

export function usePriceNegotiationDisableMutation(
  options: APIMutationOptions<string>,
) {
  const { disablePriceNegotiation } = usePriceNegotiationAPI();
  return useAPIMutation(
    (guid: string) => disablePriceNegotiation(guid),
    options,
  );
}
