import { Divider, Link, Typography } from '@material-ui/core';
import { Check, Error, Language, Mail } from '@material-ui/icons';
import {
  Color,
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { useCentralDispatchCredentials } from 'core/cental-dispatch/CentralDispatchAPI';
import { useMemo } from 'react';
import { useCanExecute } from 'shared/data/UserPermissions';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import { tryParseJSON } from 'shared/utils/DataUtils';
import styled from 'styled-components';
import cdNudgingImg from '../../assets/cd_integration_nudging.png';
import { useUserState } from 'shared/data/AppUserState';

function useBanner() {
  const storageKey = 'dismissed_cd_credentials_banner';
  const storageValue = useStorageValue(storageKey);
  const { attempt = 0, dismissed_at } = useMemo(() => {
    const defaultValue = {
      attempt: 0,
      dismissed_at: 0,
    };

    if (!storageValue) {
      return defaultValue;
    }

    return (
      tryParseJSON<{ attempt: number; dismissed_at: number }>(storageValue) ||
      defaultValue
    );
  }, [storageValue]);

  const dismiss = () => {
    writeStorageItem(
      storageKey,
      JSON.stringify({
        attempt: attempt + 1,
        dismissed_at: Date.now(),
      }),
    );
  };

  const FIVE_DAYS_IN_MS = 1000 * 60 * 60 * 24 * 5;
  const SIX_MONTHS_IN_MS = 1000 * 60 * 60 * 24 * 180;
  const shouldShow =
    attempt === 0
      ? true
      : attempt === 1
      ? Date.now() - dismissed_at > FIVE_DAYS_IN_MS
      : Date.now() - dismissed_at > SIX_MONTHS_IN_MS;

  return [shouldShow, dismiss] as const;
}

const NudgingContainer = styled.div`
  background-color: ${ColorDynamic.Silver200};
  padding: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  background-color: ${ColorDynamic.Silver200};
  padding: 8px;
  border-radius: 4px;
`;

interface LinkPreviewBlockProps {
  instructions: string;
  children?: React.ReactNode;
}

function LinkPreviewBlock({ instructions, children }: LinkPreviewBlockProps) {
  const separator = instructions.length >= 31 ? '…' : ' ';
  const preview = `${instructions
    .substring(0, 30)
    .trim()}${separator}Book on super-lb.com/exmpl`;

  return (
    <Container aria-label="CDPreviewLink">
      <Typography color="textSecondary" aria-label="header">
        <b>Preview of Instruction on External Load Boards</b>
      </Typography>
      <Inline verticalAlign="center" aria-label="preview-text">
        <Typography>{preview}</Typography>
      </Inline>
      {children}
    </Container>
  );
}

interface Props {
  instructions: string;
}

export function CDLinkPreview({ instructions }: Props) {
  const [shouldShowBanner, dismiss] = useBanner();

  const { user } = useUserState();
  const canUpdateCompanyProfile = useCanExecute('UPDATE_COMPANY_PROFILE');
  const { data: credentials } = useCentralDispatchCredentials();
  const shouldShowWarning =
    !!credentials?.login && !credentials.is_valid_web_credentials;

  if (!credentials || !user?.shipper.is_cd_integration_enabled) {
    return null;
  }

  if (shouldShowWarning) {
    return (
      <Box padding="xsmall" borderRadius="small" backgroundColor="Red50">
        <Columns space="xsmall" align="center">
          <Column width="content">
            <Error fontSize="small" htmlColor={Color.Red300} />
          </Column>
          <Column width="adaptive">
            <TextBox>
              Invalid Web credentials.{' '}
              <Link href="/profile/cd-integration/edit" target="_blank">
                Click here to update
              </Link>
            </TextBox>
          </Column>
        </Columns>
      </Box>
    );
  }

  if (
    canUpdateCompanyProfile &&
    !credentials.login &&
    credentials.uid &&
    shouldShowBanner
  ) {
    return (
      <LinkPreviewBlock instructions={instructions}>
        <Divider style={{ margin: '8px 0' }} />

        <Inline space="xxsmall" verticalAlign="center">
          <Link
            color="primary"
            href="/profile/cd-integration/edit"
            target="_blank"
            onClick={() => {
              trackEvent('Shipper Clicked on CD Integrations Banner', {
                utm_content: 'Add Web Integration',
              });
            }}
          >
            <Inline space="xxsmall" verticalAlign="center">
              <Language fontSize="small" />
              Add Web Connection
            </Inline>
          </Link>
          <Typography>
            for effortless reposting and increased efficiency.
          </Typography>
          <Link
            component="button"
            href="#"
            onClick={() => {
              trackEvent('Shipper Dismissed CD Integrations Banner', {
                utm_content: 'Web Integrations',
              });
              dismiss();
            }}
          >
            {' '}
            Dismiss
          </Link>
        </Inline>
      </LinkPreviewBlock>
    );
  }

  if (
    canUpdateCompanyProfile &&
    credentials.login &&
    !credentials.uid &&
    shouldShowBanner
  ) {
    return (
      <LinkPreviewBlock instructions={instructions}>
        <Divider style={{ margin: '8px 0' }} />

        <Inline space="xxsmall" verticalAlign="center">
          <Link
            color="primary"
            href="/profile/cd-integration/edit"
            target="_blank"
            onClick={() => {
              trackEvent('Shipper Clicked on CD Integrations Banner', {
                utm_content: 'Set up Email Integration',
              });
            }}
          >
            <Inline space="xxsmall" verticalAlign="center">
              <Mail fontSize="small" />
              Set up Email Connection
            </Inline>
          </Link>
          <Typography>for a reliable connection.</Typography>
          <Link
            component="button"
            href="#"
            onClick={() => {
              trackEvent('Shipper Dismissed CD Integrations Banner', {
                utm_content: 'Email Integrations',
              });
              dismiss();
            }}
          >
            Dismiss
          </Link>
        </Inline>
      </LinkPreviewBlock>
    );
  }

  if (canUpdateCompanyProfile && !credentials.login && shouldShowBanner) {
    return (
      <NudgingContainer>
        <Stack space="small">
          <Stack space="xxsmall">
            <Typography variant="h5">
              Posting to multiple load boards? Let Carriers request via Super
              Loadboard!
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Add connections to give carriers easy access to your loads on
              Super Loadboard.
            </Typography>

            <Inline verticalAlign="top" noWrap={true}>
              <Check fontSize="small" htmlColor={ColorDynamic.Dark100} />
              <Typography>
                Add Web Connection for effortless reposting and increased
                efficiency.
              </Typography>
            </Inline>
            <Inline verticalAlign="center" noWrap={true}>
              <Check fontSize="small" htmlColor={ColorDynamic.Dark100} />
              <Typography>
                Set up Email Connection for a reliable connection.
              </Typography>
            </Inline>
          </Stack>

          <Inline>
            <Button
              variant="primary"
              onClick={() => {
                trackEvent('Shipper Clicked on CD Integrations Banner', {
                  utm_content: 'Add Credentials Button',
                });
                window.open('/profile/cd-integration/edit', '_blank');
              }}
            >
              Add Connection
            </Button>
            <Button
              variant="text"
              onClick={() => {
                trackEvent('Shipper Dismissed CD Integrations Banner', {
                  utm_content: 'Web & Email Integrations',
                });
                dismiss();
              }}
            >
              Dismiss
            </Button>
          </Inline>
        </Stack>
        <img height="124px" src={cdNudgingImg} alt="CD Nudging" />
      </NudgingContainer>
    );
  }

  if (
    canUpdateCompanyProfile &&
    !!credentials.login &&
    !credentials.is_valid_web_credentials
  ) {
    return (
      <Box padding="xsmall" borderRadius="small" backgroundColor="Red50">
        <Columns space="xxsmall" align="center">
          <Column width="content">
            <Error fontSize="small" htmlColor={Color.Red300} />
          </Column>

          <Column width="adaptive">
            <TextBox>
              Invalid Web credentials.{' '}
              <Link
                href="#"
                onClick={() => {
                  window.open('/profile/cd-integration/edit', '_blank');
                }}
              >
                Click here to update
              </Link>
            </TextBox>
          </Column>
        </Columns>
      </Box>
    );
  }

  if (
    !!credentials.uid ||
    (!!credentials.login && credentials.is_valid_web_credentials)
  ) {
    return <LinkPreviewBlock instructions={instructions} />;
  }

  return null;
}
