import { yupEnum, yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

const REPORT_REASONS = [
  'DOUBLE_BROKERING',
  'IDENTITY_THEFT',
  'STOLEN_VEHICLE',
] as const;

export type CarrierReportDTO = InferType<typeof carrierReportSchema>;
export const carrierReportSchema = yupObject({
  reason: yupEnum(REPORT_REASONS, null).required(),
  contact: yupString().ensure().required().max(200),
  description: yupString().ensure().max(5000),
});
