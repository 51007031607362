import { ALL_PAYMENT_METHODS, ALL_PAYMENT_TERMS } from '@superdispatch/sdk';
import {
  yupArray,
  yupBoolean,
  yupDateString,
  yupEnum,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType, NumberSchema } from 'yup';

const verificationStatuses = [
  'NEW',
  'PENDING',
  'VERIFIED',
  'VERIFICATION_FAILED',
  'VERIFICATION_LOCKED',
  'ERRORED',
] as const;
export type VerificationStatusType = typeof verificationStatuses[number];

const verificationStatusesBusinessAccount = [
  'IN_REVIEW',
  'ACTION_REQUIRED',
  'VERIFIED',
  'FAILED',
] as const;

export type VerificationStatusBusinessAccountType =
  typeof verificationStatusesBusinessAccount[number];

const onBoardingStatus = [
  'STARTED',
  'OWNERS_PROVIDED',
  'PENDING',
  'COMPLETED',
] as const;
export type OnBoardingStatus = typeof onBoardingStatus[number];

export type BankAccountDTO = InferType<typeof bankAccountSchema>;
export const bankAccountSchema = yupObject({
  bank_name: yupString().nullable(true),
  display_number: yupString().nullable(true),
  created_at: yupDateString('DateISO'),
  microdeposit_initiated_date: yupDateString('DateISO'),
  guid: yupString(),
  verification_status: yupEnum(verificationStatuses, null),
  resend_microdeposit_attempts: yupNumber().nullable(false),
  bank_verification_available_date: yupDateString('DateISO'),
  is_bank_verification_available: yupBoolean(),
  validation_fail_count: yupNumber().nullable(false),
});

const superPayFlow = ['MANUAL', 'AUTOMATIC'] as const;
const superPayAlertStatus = ['ERROR', 'WARNING'] as const;
export type SuperPayUpdateSettingsDTO = InferType<
  typeof superPayUpdateSettingsSchema
>;
export const superPayUpdateSettingsSchema = yupObject({
  superpay_flow: yupEnum(superPayFlow, null),
  expedited_pay_available: yupBoolean(),
  expedited_pay_fee: yupNumber().when(
    'expedited_pay_available',
    (expeditedPayAvailable: boolean, schema: NumberSchema) => {
      return expeditedPayAvailable
        ? schema.min(1, 'Max 1-25%').max(25, 'Max 1-25%').required('Required')
        : schema.transform(() => null);
    },
  ),
  expedited_pay_eligibility: yupEnum(['ALL_ALLOWED', 'CHOSEN_ALLOWED'], null),
});

export type SuperPaySettingsDTO = InferType<typeof superPaySettingsSchema>;
export const superPaySettingsSchema = yupObject({
  on_boarding_status: yupEnum(onBoardingStatus, null),
  status: yupEnum(verificationStatusesBusinessAccount, null),
  legal_business_name: yupString().nullable().default(null),
  superpay_flow: yupEnum(superPayFlow, null),
  alert_status: yupEnum(superPayAlertStatus, null),
  can_move_to_automatic_flow: yupBoolean(),
  can_move_to_manual_flow: yupBoolean(),
  has_carrier_requested_superpay_orders: yupBoolean()
    .nullable(false)
    .default(false),
  bank_accounts: yupArray(bankAccountSchema).defined(),
  expedited_pay_available: yupBoolean(),
  expedited_pay_fee: yupNumber(),
  expedited_pay_eligibility: yupEnum(
    ['ALL_ALLOWED', 'CHOSEN_ALLOWED'],
    'ALL_ALLOWED',
  ),
});

export type SuperPayEditDTO = InferType<typeof superPayEditSchema>;

const superPayEditVehiclesSchema = yupObject({
  is_active: yupBoolean(),
  price: yupNumber().nullable(false).default(0),
  guid: yupString(),
});

const superPayEditSchema = yupObject({
  price: yupNumber().nullable(false).default(0),
  vehicles: yupArray(superPayEditVehiclesSchema).defined(),
});

export type SuperPayTermsConditionsDTO = InferType<
  typeof superPayTermsConditionsSchema
>;
export const superPayTermsConditionsSchema = yupObject({
  acceptance_token: yupString(),
});

export const expectedChargeDateScheduleSchema = yupObject({
  expected_charge_date: yupDateString('DateISO'),
});

export const disableDatesExpectedChargeSchema = yupObject({
  disabled_dates: yupArray(yupString()).defined(),
});

export type SuperPayRequestedStatisticsDTO = InferType<
  typeof superPayRequestedStatisticsSchema
>;
const superPayRequestedStatisticsSchema = yupObject({
  carriers_count: yupNumber().nullable(false).default(0),
  carriers_list: yupArray(
    yupObject({
      name: yupString().ensure(),
      logo: yupString().ensure(),
      guid: yupString().ensure(),
    }),
  ).defined(),
  delivered_orders_count: yupNumber().nullable(false).default(0),
  paid_via_superpay_percentage: yupNumber().nullable(false).default(0),
});

export type SuperPayDynamicStatisticsDTO = InferType<
  typeof dynamicStatisticsSchema
>;
export const dynamicStatisticsSchema = yupObject({
  is_dynamic: yupBoolean().default(false),
  onboarded_carriers_percentage: yupNumber().nullable(false).default(0),
  onboarded_carriers_count: yupNumber().nullable(false).default(0),
  saved_hours: yupNumber().nullable(false).default(0),
  delivered_orders_count: yupNumber().nullable(false).default(0),
  superpay_requested_statistics: superPayRequestedStatisticsSchema.nullable(),
});

export type SuperPayAlertDTO = InferType<typeof alertSchema>;
export const alertSchema = yupObject({
  status: yupEnum(superPayAlertStatus, null),
  guid: yupString(),
  name: yupString().ensure(),
  description: yupString().ensure(),
  action: yupString().ensure(),
  code: yupString().ensure(),
  is_multiple: yupBoolean(),
  order_guid: yupString().ensure(),
  order_number: yupString().ensure(),
  order_count: yupNumber(),
});

export type SuperPayRequestedCarriersDTO = InferType<
  typeof requestedCarriersSchema
>;
export const requestedCarriersSchema = yupObject({
  name: yupString().ensure(),
  logo: yupString().ensure(),
  guid: yupString().ensure(),
  delivered_orders_count: yupNumber().nullable(false).default(0),
});

export const addendumSchema = yupObject({
  superpay_addendum_required: yupBoolean().default(false),
});

export type SuperPayNowPreviewDTO = InferType<typeof nowPreviewSchema>;
export const nowPreviewSchema = yupObject({
  already_charged: yupNumber(),
  charge_due: yupNumber(),
  charge_scheduled_date: yupDateString('DateISO'),
  deposit_expected_date: yupDateString('DateISO'),
});

export type SuperPayCancelDTO = InferType<typeof cancelSchema>;
export const cancelSchema = yupObject({
  terms: yupEnum(ALL_PAYMENT_TERMS, null).required('Select Terms'),
  method: yupEnum(ALL_PAYMENT_METHODS, null).required('Select Payment Method'),
});
