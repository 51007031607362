import { startCase } from 'lodash';
import { useUserValue } from 'shared/data/UserPermissions';
import { AutocompleteBase, AutocompleteBaseProps } from './AutocompleteBase';
import { WhoAmI } from 'shared/types/user';
import { useUserState } from 'shared/data/AppUserState';

const getBrokerStatuses = (user: WhoAmI | undefined): string[] => {
  return [
    'new',
    'canceled',
    'on_hold',
    'posted_to_sdlb',
    user?.shipper.is_cd_integration_enabled && 'posted_to_cd',
    'pending',
    'declined',
    'accepted',
    'picked_up',
    'delivered',
    'invoiced',
    'paid',
    'order_canceled',
  ].filter((value) => !!value) as string[];
};

const getCustomerStatuses = () => {
  return [
    'new',
    'submitted',
    'scheduled',
    'picked_up',
    'delivered',
    'completed',
    'order_canceled',
  ];
};

export function formatStatus(status: string) {
  switch (status) {
    case 'canceled':
      return 'Offer Canceled';
    case 'posted_to_sdlb':
      return 'Posted to SLB';
    case 'posted_to_cd':
      return 'Posted to CD';
    case 'posted_to_lbs':
      return 'Posted to Load Boards';
    case 'order_canceled':
      return 'Canceled';
    case 'delivery_verified':
      return 'Delivery Verified';
    default:
      return startCase(status);
  }
}

interface StatusAutocompleteProps
  extends Omit<
    AutocompleteBaseProps<string>,
    'options' | 'onChange' | 'onSelect'
  > {
  onChange: (values: string[]) => void;
}

export function StatusAutocomplete({
  value,
  onChange,
  onClose,
}: StatusAutocompleteProps) {
  const { user } = useUserState();
  const options = useUserValue(getBrokerStatuses(user), getCustomerStatuses());

  return (
    <AutocompleteBase
      value={value}
      onClose={onClose}
      options={options}
      onChange={(_, option) => onChange(option)}
      getOptionLabel={(option: string) => formatStatus(option)}
      filterOptions={(results, { inputValue }) =>
        results.filter((x) =>
          x.toLowerCase().includes(inputValue.trim().toLowerCase()),
        )
      }
    />
  );
}
