import { Box, Link, Typography } from '@material-ui/core';
import { WorkOutline } from '@material-ui/icons';
import { FormikTextField } from '@superdispatch/forms';
import {
  ColorDynamic,
  Column,
  Columns,
  InfoTooltip,
  Stack,
} from '@superdispatch/ui';
import { Button, TextBox } from '@superdispatch/ui-lab';
import { getIn, useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import Order from 'shared/types/order';
import { CDLinkPreview } from '../../form/CDLinkPreview';
import { BuildLoadsFormValues } from './BuildLoadsDrawer';

interface LoadStepInstructionsProps {
  prefix?: string;
  order?: Order;
}

export function LoadStepInstructions({
  order,
  prefix,
}: LoadStepInstructionsProps) {
  const { user } = useUserState();
  const { values, setFieldValue } = useFormikContext<BuildLoadsFormValues>();

  const loadBoardInstructionsName = `${
    prefix ? `${prefix}.` : ''
  }loadboard_instructions`;

  const instructionsName = `${prefix ? `${prefix}.` : ''}instructions`;

  const { instructions, loadboardInstructions } = useMemo(() => {
    return {
      instructions: String(getIn(values, instructionsName, '')),
      loadboardInstructions: String(
        getIn(values, loadBoardInstructionsName, ''),
      ),
    };
  }, [instructionsName, loadBoardInstructionsName, values]);

  const handleSameAsOriginalOrderClick = useCallback(() => {
    setFieldValue(instructionsName, order?.instructions || '');
    setFieldValue(
      loadBoardInstructionsName,
      order?.loadboard_instructions || '',
    );
  }, [order]);

  return (
    <Stack space="medium">
      <Columns align="center">
        <Column width="fluid">
          <Typography variant="h3">Instructions</Typography>
        </Column>
        <Column width="content">
          <Button
            variant="text"
            type="button"
            startIcon={<WorkOutline />}
            onClick={handleSameAsOriginalOrderClick}
          >
            Same as Original Order
          </Button>
        </Column>
      </Columns>
      {user?.shipper.shipper_type === 'BROKER' &&
        user.order_form_settings.is_loadboard_instructions_visible && (
          <Stack space="small">
            <FormikTextField
              name={loadBoardInstructionsName}
              label={
                <Box display="flex" justifyContent="space-between">
                  <InfoTooltip
                    title={
                      user.shipper.is_cd_integration_enabled
                        ? 'Visible inside the Super Loadboard. \nCD Post includes link to your load on Super Loadboard.'
                        : 'Visible inside the Super Loadboard.'
                    }
                    TextProps={{
                      color: 'textPrimary',
                    }}
                  >
                    Loadboard Instructions
                  </InfoTooltip>
                  <TextBox color="secondary">
                    {`${loadboardInstructions.length || 0} of 500`}
                  </TextBox>
                </Box>
              }
              inputProps={{
                maxLength: 500,
              }}
              multiline={true}
              fullWidth={true}
              rows={2}
              helperText={
                user.shipper.is_cd_integration_enabled &&
                'Only 30 characters visible on external load boards.'
              }
            />

            <CDLinkPreview instructions={loadboardInstructions || ''} />
          </Stack>
        )}

      {user?.shipper.shipper_type === 'BROKER' &&
        user.order_form_settings.is_instructions_visible && (
          <Stack space="xxsmall">
            <FormikTextField
              name={instructionsName}
              label={
                <Box display="flex" justifyContent="space-between">
                  <InfoTooltip
                    title="Visible to carrier before and after accepting the load offer"
                    TextProps={{
                      color: 'textPrimary',
                    }}
                  >
                    Order Instructions
                  </InfoTooltip>

                  <TextBox color="secondary">
                    {`${instructions.length || 0} of 10000`}
                  </TextBox>
                </Box>
              }
              inputProps={{
                maxLength: 10000,
              }}
              multiline={true}
              fullWidth={true}
              rows={5}
            />
            <Typography style={{ color: ColorDynamic.Dark300 }}>
              <span>By posting this load, you are agreeing to standard </span>
              <Link
                href="https://www.superdispatch.com/terms-of-service#tab-id-2"
                rel="noopener noreferrer"
                target="_blank"
              >
                waiver
              </Link>
              <span>.</span>
            </Typography>
          </Stack>
        )}
    </Stack>
  );
}
