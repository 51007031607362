import {
  Box,
  Divider,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { Inline } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { FunctionComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import { useProductTiers } from 'shared/data/TiersUtils';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import Order from 'shared/types/order';
import { CurrentUser } from 'shared/types/user';
import ButtonGroupDropdown from 'shared/ui/ButtonGroupDropdown';
import { ActionRestrictedForUnverifiedShipper } from '../../../shipper-profile/data/ActionRestricted';
import { showSendOffer } from '../actions/OrderActionUtils';
import { PostToPrivateNetworkMenuItem } from '../actions/PostToPrivateNetwork';
import { OrderFormType } from './helpers/orderFormHelpers';

export interface HeaderProps {
  title: string;
  order: Partial<Order>;
  isSubmitDisabled: boolean;
  onSave: (actionType: OrderFormType, args?: Record<string, unknown>) => void;
  onBack: () => void;
  isOfferValidating: boolean;
  source: 'order_edit_page' | 'order_create_page';
}

const postToLBsBtnIsVisible = (order: Partial<Order>, user?: CurrentUser) => {
  const allowedStatuses = [
    'new',
    'canceled',
    'order_canceled',
    'declined',
    undefined,
  ];
  const allowedStatus =
    allowedStatuses.includes(order.status) ||
    (order.status === 'pending' && order.active_offer?.is_counter_offer);
  return (
    user?.shipper.shipper_type === 'BROKER' &&
    !order.is_archived &&
    allowedStatus &&
    !order.is_posted_to_centraldispatch &&
    !order.is_posted_to_loadboard
  );
};

function canSaveAndSendOffer(order: Partial<Order>, user?: CurrentUser) {
  const isParentOrder = order.has_loads && !order.is_load;

  if (isParentOrder) {
    return false;
  }

  if (!order.status && user?.shipper.shipper_type === 'BROKER') {
    return true;
  }

  return showSendOffer(order as Order, user);
}

export const OrderFormHeader: FunctionComponent<HeaderProps> = ({
  order,
  title,
  isSubmitDisabled,
  onSave,
  onBack,
  source,
  isOfferValidating,
}) => {
  const { user } = useUserState();
  const shipper = user?.shipper;
  const shipperType = shipper?.shipper_type;
  const isParentOrder = order.has_loads && !order.is_load;
  const { isAdvancedTier } = useProductTiers();

  return (
    <>
      <Toolbar>
        <Box paddingRight="4px">
          <IconButton>
            <ArrowBack fontSize="medium" color="action" onClick={onBack} />
          </IconButton>
        </Box>
        <Box flex={1} display="flex" flexWrap="wrap">
          <Inline verticalAlign="bottom" space="small">
            <Typography variant="h2">{title}</Typography>
            {shipperType === 'BROKER' && isAdvancedTier && (
              <Link
                color="textPrimary"
                rel="noreferrer"
                target="_blank"
                aria-label="manage order fields"
                component={RouterLink}
                to="/manage-fields"
              >
                Manage Fields
              </Link>
            )}
          </Inline>
        </Box>

        <Box>
          <Grid
            container={true}
            spacing={1}
            data-intercom-target="order actions"
          >
            {canSaveAndSendOffer(order, user) && (
              <Grid item={true}>
                <Button
                  variant="neutral"
                  disabled={isSubmitDisabled}
                  type="button"
                  pending={isOfferValidating}
                  onClick={() => {
                    onSave('save_and_send_offer');
                    trackEventLegacy('Opened Send Offer Drawer', {
                      utm_medium: source,
                      order_guid: order.guid,
                    });
                  }}
                >
                  <ActionRestrictedForUnverifiedShipper
                    availableForInternationalShippers={true}
                  >
                    Save &amp; Send Offer
                  </ActionRestrictedForUnverifiedShipper>
                </Button>
              </Grid>
            )}

            {!isParentOrder && postToLBsBtnIsVisible(order, user) && (
              <Grid item={true}>
                <ButtonGroupDropdown
                  disabled={isSubmitDisabled}
                  color="primary"
                  variant="neutral"
                  ButtonGroupProps={{
                    'aria-label': 'save and post to LBs dropdown',
                    // @ts-expect-error ButtonGroupProps does not support data attributes
                    'data-intercom-target': 'save and post slb',
                  }}
                  label={
                    <ActionRestrictedForUnverifiedShipper>
                      Save & Post to SLB
                    </ActionRestrictedForUnverifiedShipper>
                  }
                  onClick={() => {
                    onSave('save_and_post_to_sdlb');
                    trackEventLegacy('Clicked Post to Super Loadboard', {
                      utm_medium: source,
                    });
                  }}
                >
                  {user?.shipper.is_cd_integration_enabled && (
                    <MenuItem
                      onClick={() => {
                        onSave('save_and_post_to_all');
                        trackEventLegacy('Clicked Post to SLB & CD', {
                          utm_medium: source,
                        });
                      }}
                    >
                      <ActionRestrictedForUnverifiedShipper>
                        Save & Post to SLB & CD
                      </ActionRestrictedForUnverifiedShipper>
                    </MenuItem>
                  )}

                  {user?.shipper.is_private_network_enabled && (
                    <PostToPrivateNetworkMenuItem
                      label="Save & Post to Private Loadboard"
                      onSelect={(_event, groupGuid) => {
                        onSave('save_and_post_to_private_loadboard', {
                          groupGuid,
                        });
                        trackEventLegacy('Clicked Post to Private Loadboard', {
                          utm_medium: source,
                        });
                      }}
                    />
                  )}
                </ButtonGroupDropdown>
              </Grid>
            )}

            <Grid item={true}>
              <Button
                disabled={isSubmitDisabled}
                type="button"
                variant="primary"
                data-intercom-target="save order"
                onClick={() => onSave('save')}
              >
                Save
              </Button>
            </Grid>

            {user?.shipper.shipper_type === 'CUSTOMER' && (
              <Grid item={true}>
                <Button
                  disabled={isSubmitDisabled}
                  type="button"
                  onClick={() => onSave('save_and_submit')}
                  variant="primary"
                >
                  Save and Submit
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Toolbar>

      <Divider />
    </>
  );
};
