import { Divider, MenuItem } from '@material-ui/core';
import { Button } from '@superdispatch/ui-lab';
import { usePrivateNetworkGroups } from 'manage-carriers/data/PrivateNetworkAPI';
import * as React from 'react';
import { MouseEvent } from 'react';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import Order from 'shared/types/order';
import ButtonGroupDropdown from 'shared/ui/ButtonGroupDropdown';
import { ActionRestrictedForUnverifiedShipper } from '../../../shipper-profile/data/ActionRestricted';
import {
  PostToPrivateNetworkMenuItem,
  PostToPrivateNetworkMenuItems,
} from './PostToPrivateNetwork';
import { WhoAmI } from 'shared/types/user';

type PostLoadboardTarget = 'cd' | 'sdlb' | 'private_loadboard';

export function getAvailablePostTarget(
  orders: Order[],
  user: WhoAmI | undefined,
): PostLoadboardTarget[] {
  const allowedStatuses: Array<Order['status']> = [
    'new',
    'canceled',
    'declined',
    'order_canceled',
    'pending',
  ];

  const activeOrders = orders
    .filter((order) => allowedStatuses.includes(order.status))
    .filter(
      (order) =>
        order.status !== 'pending' || order.active_offer?.is_counter_offer,
    )
    .filter((order) => !order.is_archived);

  const canPostToSDLoadboard =
    activeOrders.filter(
      (order) =>
        !order.is_posted_to_loadboard || order.is_posted_to_private_loadboard,
    ).length === orders.length;
  const canPostToCDLoadboard =
    !!user?.shipper.is_cd_integration_enabled &&
    activeOrders.some((order) => !order.is_posted_to_centraldispatch);
  const canPostToPrivateLoadboard =
    user?.shipper.is_private_network_enabled &&
    activeOrders.filter(
      (order) =>
        !order.is_posted_to_loadboard && !order.is_posted_to_private_loadboard,
    ).length === orders.length;

  const target: PostLoadboardTarget[] = [];

  if (canPostToPrivateLoadboard) {
    target.push('private_loadboard');
  }

  if (canPostToSDLoadboard) {
    target.push('sdlb');
  }

  if (canPostToCDLoadboard) {
    target.push('cd');
  }

  return target;
}

type PostLoadboardAction =
  | 'post_to_cd'
  | 'post_to_sdlb'
  | 'post_to_all'
  | 'post_to_private_loadboard';

interface PostLoadboardButtonProps {
  isLoading?: boolean;
  target: PostLoadboardTarget[];
  source: string;
  isMenuItem?: boolean;
  onClick: (
    event: MouseEvent,
    action: PostLoadboardAction,
    args?: Record<string, unknown>,
  ) => void;
}

export function PostLoadboardButton({
  target,
  isLoading,
  source,
  isMenuItem,
  onClick,
}: PostLoadboardButtonProps) {
  const { data: privateNetwork } = usePrivateNetworkGroups();

  function handleClick(action: PostLoadboardAction, groupGuid?: string) {
    return (event: MouseEvent) => {
      onClick(event, action, { groupGuid });
    };
  }

  function clickAnalytics(type?: 'cd' | 'sdlb' | 'private_loadboard') {
    switch (type) {
      case 'sdlb':
        trackEventLegacy('Clicked Post to Super Loadboard', {
          utm_medium: source,
        });
        break;
      case 'cd':
        trackEventLegacy('Clicked Post to Central Dispatch', {
          utm_medium: source,
        });
        break;
      case 'private_loadboard':
        trackEventLegacy('Clicked Post to Private Loadboard', {
          utm_medium: source,
        });
        break;
      default:
        trackEventLegacy('Clicked Post to SLB & CD', {
          utm_medium: source,
        });
    }
  }

  const filteredTarget = target.filter(
    (item) =>
      item !== 'private_loadboard' ||
      !!privateNetwork?.all_private_network_carrier_count,
  );

  if (!isMenuItem) {
    return filteredTarget.length > 1 ? (
      <ButtonGroupDropdown
        isLoading={isLoading}
        onClick={(event) => {
          handleClick('post_to_sdlb')(event);
          clickAnalytics('sdlb');
        }}
        color="primary"
        variant="neutral"
        label={
          <ActionRestrictedForUnverifiedShipper>
            Post to SLB
          </ActionRestrictedForUnverifiedShipper>
        }
        data-intercom-target="post to slb"
        DropDownButtonProps={{
          // @ts-expect-error data-intercom-target is a valid prop.
          'data-intercom-target': 'Post to SLB Button Dropdown',
        }}
      >
        {filteredTarget.includes('sdlb') && filteredTarget.includes('cd') && (
          <MenuItem
            onClick={(event: React.MouseEvent) => {
              handleClick('post_to_all')(event);
              clickAnalytics();
            }}
          >
            <ActionRestrictedForUnverifiedShipper>
              Post to SLB & CD
            </ActionRestrictedForUnverifiedShipper>
          </MenuItem>
        )}

        {filteredTarget.includes('private_loadboard') && (
          <>
            <Divider />

            <PostToPrivateNetworkMenuItems
              onSelect={(event, groupGuid) => {
                handleClick('post_to_private_loadboard', groupGuid)(event);
                clickAnalytics('private_loadboard');
              }}
            />
          </>
        )}
      </ButtonGroupDropdown>
    ) : filteredTarget[0] === 'sdlb' ? (
      <Button
        variant="neutral"
        pending={isLoading}
        onClick={(event) => {
          handleClick('post_to_sdlb')(event);
          clickAnalytics(filteredTarget[0]);
        }}
      >
        <ActionRestrictedForUnverifiedShipper>
          Post to SLB
        </ActionRestrictedForUnverifiedShipper>
      </Button>
    ) : null;
  }

  return (
    <div>
      {filteredTarget.includes('sdlb') && (
        <MenuItem
          key="post_to_sdlb"
          disabled={isLoading}
          onClick={(event) => {
            handleClick('post_to_sdlb')(event);
            clickAnalytics('sdlb');
          }}
        >
          <ActionRestrictedForUnverifiedShipper>
            Post to SLB
          </ActionRestrictedForUnverifiedShipper>
        </MenuItem>
      )}

      {filteredTarget.includes('cd') && filteredTarget.includes('sdlb') && (
        <MenuItem
          key="post_to_all"
          onClick={(event) => {
            handleClick('post_to_all')(event);
            clickAnalytics();
          }}
        >
          <ActionRestrictedForUnverifiedShipper>
            Post to SLB & CD
          </ActionRestrictedForUnverifiedShipper>
        </MenuItem>
      )}

      {filteredTarget.includes('private_loadboard') && (
        <PostToPrivateNetworkMenuItem
          label="Post to Private Loadboard"
          onSelect={(event, groupGuid) => {
            handleClick('post_to_private_loadboard', groupGuid)(event);
            clickAnalytics('private_loadboard');
          }}
        />
      )}
    </div>
  );
}
